<!--
  DO NOT EDIT THIS FILE

  File is duplicated across sites, so should be kept in sync
-->
<template>
  <OfferSandboxBase @reloadOfferSample="createSandboxOfferQuestionnaire()" backgroundColor="white">
    <v-container fluid>
      <v-row>
        <v-col>
          <v-card class="pa-3 bg-canvas">
            <v-card-text>
              <div class="d-flex">
                <div>
                  <div class="d-flex align-center">
                    <h1 class="mt-0 mb-2 font-weight-bold">Udfyld dit tilbud</h1>
                    <v-chip color="darkPurple" class="ml-2">Leverandørvisning</v-chip>
                  </div>

                  <div class="mt-2 mb-7">
                    <p>
                      Kravbesvarelse og prisafgivelse herunder udgør dit tilbud
                    </p>
                    <p>
                      Udfyld dit tilbud ved (1) at besvare evalueringskravene,
                      (2) afgive priser og (3) bekræfte, at dit tilbud opfylder
                      samtlige af kundens krav.
                    </p>
                  </div>
                </div>
              </div>
              <v-card>
                <v-card-text class="pa-10">
                  <v-row>
                    <v-col cols="auto">
                      <StepStatus
                        :label="1"
                        :complete="isSectionComplete(specificationSections)"
                      />
                    </v-col>
                    <v-col>
                      <h2 class="mt-0 mb-2">Kravbesvarelse</h2>
                      <p>Besvarelsen er opdelt i følgende:</p>
                      <ul class="mt-0">
                        <li>
                          ”Funktionelle krav”, som omhandler kontraktkrav til
                          selve ydelsen
                        </li>
                        <li>
                          ”Non-funktionelle krav”, som omhandler kontraktkrav
                          til samarbejdet
                        </li>
                        <li>
                          ”Evaluering”, som omhandler evalueringskrav som kunden
                          ønsker at evaluere på
                        </li>
                      </ul>
                      <p>
                        <b>Kontraktkrav</b> er krav, dit tilbud skal leve op
                        til. De kræver ikke besvarelse fra din side, men er
                        afgørende for, om dit tilbud opfylder alle kundens krav
                        (nedenstående trin 3). <b>Evalueringskrav</b> indgår i
                        kundens evaluering af dit tilbud, og skal besvares.
                      </p>
                      <p>
                        Under fanen ”Non-funktionelle krav” kan kunden bede dig
                        om at udfylde relevante informationer i kontraktens
                        bilag. Såfremt kunden ønsker dette, så vil det være
                        indikeret med et udråbstegn ud for det relevante bilag.
                      </p>
                      <p>
                        Under fanen ”Evaluering”, vil du kunne se hvilke
                        evalueringskrav der indgår, og hvad kunden vil tillægge
                        vægt i forbindelse med evaluering af kvalitet (hvis
                        kvalitet indgår i evalueringen) Du besvarer
                        evalueringskravene ved at downloade bilag 3, udfylde
                        bilaget og uploade din besvarelse.
                      </p>
                      <v-dialog v-model="offerPreviewDialog" max-width="95%">
                        <template #activator="{ props }">
                          <ArrowButton
                            v-bind="props"
                            class="mt-2"
                            :class="{ secondary: isSectionComplete(specificationSections) }"
                          >Besvar evalueringskrav</ArrowButton>
                        </template>
                        <v-card>
                          <OfferDetailsForm
                            v-if="offerQuestionnaire"
                            :tender="tender"
                            :offerQuestionnaire="offerQuestionnaire"
                            :tabType="'SupplierSpecification'"
                            :readonly="true"
                            @closeDialog="closeOfferPreviewDialog"
                          />
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                  <v-divider class="my-8"></v-divider>
                  <v-row>
                    <v-col cols="auto">
                      <StepStatus
                        :label="2"
                        :complete="isSectionComplete([priceSection])"
                      />
                    </v-col>
                    <v-col>
                      <h2 class="mt-0 mb-2">Prisafgivelse</h2>
                      <p>
                        Prisafgivelsen er overordnet set opdelt i den/de
                        ydelsesområder, som kunden har valgt er en del af deres
                        konkrete anskaffelse.
                      </p>
                      <p>
                        Hvert ydelsesområde har én eller flere pristabeller med
                        prispunkter, du skal udfylde. For hver pristabel findes
                        en vejledning til udfyldelse af pristabellen. Derudover
                        er der - for nogle af kolonnerne i pristabellen -
                        angivet en særskilt vejledning til netop udfyldelsen af
                        dette input.
                      </p>
                      <p>
                        I takt med at du udfylder prispunkterne beregnes den
                        evalueringstekniske pris løbende, og du kan se, hvordan
                        den evalueringstekniske pris aggregeres op på
                        pristabel-niveau og på ydelsesområde-niveau.
                      </p>
                      <p>
                        I 'Opsummering' (til højre), kan du følge de priser, du
                        har afgivet, samt se en dokumentation af, hvordan den
                        evalueringstekniske pris udregnes.
                      </p>
                      <p>
                        <b>Bemærk</b> priser skal afgives ekskl. moms og inkl.
                        kundebetaling til SKI.
                        <DimsTooltip>
                          <p>
                            Leverandøren skal på vegne af Kunden videreformidle
                            kundebetalingen på 1,0 % af Kundens købssum til SKI.
                            Leverandøren skal derfor indregne kundens betaling
                            til SKI på 1,0 % i sine tilbudspriser i det konkrete
                            indkøb.
                          </p>
                          <p>Kundebetalingen beregnes efter følgende formel:</p>
                          <p class="text-decoration-underline">
                            Kundens købssum eksklusive moms i DKK x 1,0 % =
                            Kundebetalingen i DKK
                          </p>
                          <p>
                            Leverandørens rapportering samt formidling af
                            kundens betaling til SKI er beskrevet nærmere i
                            Bilag 1 Trepartsaftale.
                          </p>
                        </DimsTooltip>
                      </p>
                      <v-dialog v-model="offerFormDialog" max-width="95%">
                        <template #activator="{ props }">
                          <ArrowButton
                            v-bind="props"
                            class="mt-2"
                            :class="{ secondary: isSectionComplete([priceSection]) }"
                          >Afgiv priser
                          </ArrowButton>
                        </template>
                        <v-card>
                          <OfferDetailsForm
                            v-if="offerQuestionnaire"
                            :tender="tender"
                            :offerQuestionnaire="offerQuestionnaire"
                            :tabType="'SupplierOffer'"
                            :readonly="true"
                            @closeDialog="closeOfferFormDialog"
                          />
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                  <v-divider class="my-8"></v-divider>
                  <v-row>
                    <v-col cols="auto">
                      <StepStatus
                        :label="3"
                        :complete="false"
                      />
                    </v-col>
                    <v-col>
                      <h2 class="mt-0 mb-2">Kundens kravspecifikation</h2>
                      <div>
                        Bekræft om dit tilbud opfylder samtlige krav i kundens
                        kravspecifikation.
                      </div>
                      <v-radio-group
                        hide-details
                        disabled
                        density="compact"
                      >
                        <v-radio :value="true">
                          <template #label>
                            <small>Ja, alle krav er opfyldt</small>
                          </template>
                        </v-radio>
                        <v-radio :value="false">
                          <template #label>
                            <small>
                              Nej, alle krav er
                              <span class="font-weight-bold">ikke</span>
                              opfyldt
                            </small>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-divider class="my-8"></v-divider>
                  <v-row>
                    <v-col cols="auto">
                      <StepStatus
                        :label="4"
                        :complete="false"
                      />
                    </v-col>
                    <v-col>
                      <p>Du kan her uploade det ESPD, som ligger til grund for din optagelse på aftalen.</p>
                      <v-btn
                        class="primary-button-with-arrow"
                      >
                        <span>Upload ESPD-dokument</span>
                        <v-icon size="small">mdi-upload</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-divider class="my-8"></v-divider>
                  <v-row>
                    <v-col cols="auto">
                      <StepStatus :label="5" :complete="false" />
                    </v-col>
                    <v-col>
                      <h2 class="mt-0 mb-2">Se og send tilbud</h2>
                      <p>Færdiggør trin 1, 2 og 3, for at sende dit tilbud</p>
                      <v-dialog
                        persistent
                        scrollable
                        max-width="80%"
                      >
                        <template #activator="{ props }">
                          <ArrowButton
                            disabled
                            v-bind="props"
                            class="mt-2"
                          >Se og send tilbud</ArrowButton>
                        </template>
                        <v-card>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <TcoCalculationSummary
            :questions="questions"
            :tcoCalculationResult="tcoCalculationResult"
            cardStyle="#fff9ef"
            tableStyle="transparent"
          />
        </v-col>
      </v-row>
    </v-container>
  </OfferSandboxBase>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { Tender, OfferSandboxBase, OfferDetailsForm, useBackend, DeepQuestionnaireRoot, usePreviewOfferQuestionnaire } from '@dims/components';
import TcoCalculationSummary from './OfferDetails/TcoCalculation/TcoCalculationSummary.vue';
import { setSupplierPriceTab, setSupplierSpecificationTabs } from '../../services/questionnaireTabConfig';

const { tender } = defineProps<{ tender: Tender }>();
const backend = useBackend();
const offerFormDialog = ref(false);
const offerPreviewDialog = ref(false);
const offerQuestionnaire = ref<DeepQuestionnaireRoot>();
const excludedQuestions = ['21390c89-ec77-40ca-a88c-1f5d8cc02254'];
const priceSection = ref(3);
const specificationSections = ref([1, 2]);

async function createSandboxOfferQuestionnaire() {
  offerQuestionnaire.value = await usePreviewOfferQuestionnaire(tender.id, backend);
  priceSection.value = setSupplierPriceTab(offerQuestionnaire.value.questionnaire);
  specificationSections.value = setSupplierSpecificationTabs(offerQuestionnaire.value.questionnaire);
}

const tcoCalculationResult = computed(() => offerQuestionnaire.value?.questionnaire.tcoCalculationResult);

// Questions used for offer status overview
// Exclude the first question (general information) from the summary
const questions = computed(() => offerQuestionnaire.value?.questionnaire.questions[priceSection.value]?.questions?.filter(
  (q) => !excludedQuestions.includes(q.questionId),
) ?? []);

function isSectionComplete(sections: number[]) {
  const topLevelQuestions = offerQuestionnaire.value?.questionnaire.questions;
  return topLevelQuestions
    ? sections.every((section) => topLevelQuestions[section]?.questions?.every((q) => q.complete))
    : false;
}

function closeOfferFormDialog() {
  offerFormDialog.value = false;
}

function closeOfferPreviewDialog() {
  offerPreviewDialog.value = false;
}

</script>
